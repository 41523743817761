<template>
  <div class="body-wrapper">
    <Headernav />
    <noscript>
      <div style="display:inline;">
      <img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/928263683/?label=snP5CKn78m8Qg9zQugM&amp;guid=ON&amp;script=0"/>
      </div>
    </noscript>
    <div class="w-full">
      <div class="pt-12 pb-12">
        <div class="container text-center md:pt-12 md:pb-12">
          <div class="font-bold text-3xl text-gray-800 mb-3">Message sent successfully!</div>
          <div class="text-xl text-gray-600 mb-12">Thanks for your kind interest in SGO Philippines Inc. Our team will reach out to you in a while.</div>
          <div class="btn-width m-auto"><router-link class="m-auto" to="/"><div class="black-btn">Back To Home</div></router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias for src
import Headernav from '@/components/Headernav.vue'
export default {
  components: {
    Headernav
  },
  mounted () {
    // Event snippet for Conversion Code conversion page
    gtag('event', 'conversion', {'send_to': 'AW-928263683/snP5CKn78m8Qg9zQugM'});
  }
}
</script>

<style>
</style>
